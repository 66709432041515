import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';
import debounce from 'lodash/debounce';
import pickBy from 'lodash/pickBy';

import { SpeedDial, BubbleList, BubbleListItem } from 'react-speed-dial';
import { Card, CardTitle } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import { List, ListItem } from 'material-ui/List';
import IconButton from 'material-ui/IconButton';

import {
  fetchHousehold,
  modifyHousehold,
  deleteHousehold,
  fetchHouseholdNotes,
  removeCarerFromHousehold,
} from '../HouseholdActions';
import { filterHouseholdNotes } from '../HouseholdAPI';
import { updateRecent } from '../../App/AppActions';

import PersonAdd from '../../OtherIndividual/components/AddPerson';
import PersonEdit from '../../OtherIndividual/components/EditPerson';
import CarerAdd from '../../Carer/components/CarerAdd';

import PrintOptions from '../components/PrintOptions';

import HouseholdModel from '../HouseholdModel';
import Form from '../../../components/Form';
import Title from '../../../components/Title';
import AccommodationCard from '../../Placement/components/AccommodationCard';
import PlacementCard from '../../Placement/components/PlacementCard';
import PersonCard from '../../../components/PersonCard';
import GlobalLoading from '../../../components/GlobalLoading';
import GlobalError from '../../../components/GlobalError';
import Attachment from '../../../components/Attachment';
import ObjectLogs from '../../Log/components/ObjectLogs';
import { getObjectLogs } from '../../Log/LogAPI';
import Assign from '../../Staff/components/Assign';
import BackLink from '../../../components/BackLink';
import DeletePrompt from '../../../components/DeletePrompt';
import RemovePrompt from '../../../components/RemovePrompt';
import TextField from '../../../components/TextField';
import SelectField from '../../../components/SelectField';
import NoteAdd from '../../Placement/components/NoteAdd';
import NoteCard from '../../Placement/components/NoteCard';
import noteModel from '../../Placement/NoteModel';

import { colours, icons } from '../../../theme';
import deepCompare from '../../../util/deepCompare';

class HouseholdDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      householdType: '',
      showAddPerson: false,
      showAddSecondary: false,
      speedDialOpen: false,
      attachmentsExpanded: false,
      currentPlacementsExpanded: true,
      detailsExpanded: true,
      primaryContactExpanded: true,
      emergencyContactExpanded: true,
      showMemberEdit: false,
      showAssignCoord: false,
      showDelPro: false,
      showRemPro: false,
      showNoteAdd: false,
      showPrintOptions: false,
      search: {
        text: '',
        category: '',
        communication: '',
        incidentReporting: '',
      },
      notes: [],
      error: false,
    };
    this.onChange = debounce(this.onChange.bind(this), 800);
    this.doSearch = debounce(this.doSearch.bind(this), 800);
    props
      .dispatch(fetchHousehold(props.match.params.id))
      .then(({ household }) => {
        if (household.name) {
          props.dispatch(
            updateRecent({
              type: 'household',
              id: props.match.params.id,
              title: household.name,
            })
          );
        }
      })
      .catch(() => this.setState({ error: true }));
  }
  shouldComponentUpdate(props, state) {
    const { user, household, notes } = this.props;
    return (
      !deepCompare(state, this.state) ||
      !deepCompare(props.user, user) ||
      !deepCompare(props.household, household) ||
      !deepCompare(props.notes, notes)
    );
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { match } = this.props;
    if (!isEmpty(props.household) && !props.notes) {
      props.dispatch(fetchHouseholdNotes(match.params.id));
    }
    if (props.notes) {
      this.setState({ notes: props.notes });
    }
    if (!isEmpty(props.household)) {
      this.setState({
        household: props.household,
        householdType: props.household.householdType,
      });
    }
  }
  onChange(val) {
    const { match, dispatch } = this.props;
    const { id } = match.params;
    const key = Object.keys(val).join();
    const prevVal = { [key]: this.state.household[key] };
    dispatch(modifyHousehold(id, val, prevVal));
  }
  getLogs() {
    getObjectLogs(this.props.household._id).then(logs =>
      this.setState({ logs })
    );
  }
  doSearch() {
    // NOTE: EXCLUDE SENSITIVE???
    const search = pickBy(this.state.search);
    if (isEmpty(search)) this.setState({ searchResults: '' });
    else
      filterHouseholdNotes(this.props.match.params.id, search).then(
        searchResults => this.setState({ searchResults })
      );
  }
  selectMember(member) {
    this.setState({
      member,
      showMemberEdit: true,
    });
  }
  renderDetails(readOnly) {
    const { detailsExpanded, household } = this.state;
    return (
      <Card
        className="content-box"
        expanded={detailsExpanded}
        onExpandChange={() =>
          this.setState({ detailsExpanded: !detailsExpanded })
        }
      >
        <CardTitle subtitle="My Details" actAsExpander showExpandableButton />
        <Form
          readOnly={readOnly}
          model={HouseholdModel}
          section="main"
          expandable
          object={household}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderCarers(deletable) {
    const { primaryCarer, secondaryCarer } = this.props.household;
    return (
      <Card className="content-box" initiallyExpanded>
        <CardTitle subtitle="Carers" actAsExpander showExpandableButton />
        {primaryCarer && (
          <PersonCard
            person={primaryCarer}
            rel={primaryCarer.approvalDate ? 'carer' : 'prospect'}
            key={primaryCarer._id}
            title="Primary Carer"
            expandable
            action={
              secondaryCarer && deletable ? (
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    this.setState({
                      removePrimary: true,
                      showRemPro: true,
                      removeExtra: `${
                        secondaryCarer.contact.fullName
                      } will become the primary carer for this household. ${
                        primaryCarer.contact.fullName
                      } and all their details will not be removed from HEART1869.`,
                    });
                  }}
                >
                  <icons.RemoveIcon color={colours.kRed} />
                </IconButton>
              ) : null
            }
          />
        )}
        {secondaryCarer && (
          <PersonCard
            person={secondaryCarer}
            rel={secondaryCarer.approvalDate ? 'carer' : 'prospect'}
            key={secondaryCarer._id}
            title="Secondary Carer"
            expandable
            action={
              secondaryCarer && deletable ? (
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    this.setState({
                      removePrimary: false,
                      showRemPro: true,
                      removeExtra: `${
                        secondaryCarer.contact.fullName
                      } and all their details will not be removed  from HEART1869.`,
                    });
                  }}
                >
                  <icons.RemoveIcon color={colours.kRed} />
                </IconButton>
              ) : null
            }
          />
        )}
      </Card>
    );
  }
  renderPreviousCarers() {
    const { previousCarers } = this.props.household;
    return previousCarers.length > 0 ? (
      <Card className="content-box">
        <CardTitle
          subtitle="Previous Carers"
          actAsExpander
          showExpandableButton
        />
        {previousCarers.map(pC => (
          <PersonCard
            person={pC}
            rel={pC.approvalDate ? 'carer' : 'prospect'}
            key={pC._id}
            expandable
          />
        ))}
      </Card>
    ) : null;
  }
  renderHouseholdMembers(readOnly) {
    const { otherIndividuals } = this.props.household;
    const { householdType } = this.state;
    return (
      <Card className="content-box">
        <CardTitle
          subtitle="Other Household Members"
          actAsExpander
          showExpandableButton
        />
        {otherIndividuals.map(o => {
          if (householdType === 'Community') {
            if (
              o.otherType === 'primaryContact' ||
              o.otherType === 'emergencyContact'
            ) {
              return null;
            }
          }
          return (
            <PersonCard
              expandable
              readOnly={readOnly}
              person={o}
              rel="other"
              key={o._id}
              select={() => this.selectMember(o)}
            />
          );
        })}
      </Card>
    );
  }
  renderPlacements() {
    const {
      currentPlacementsExpanded,
      archivedPlacementsExpanded,
      household: { cases },
    } = this.state;
    const currentPlacements = cases.filter(
      c => !c.leftCareDate || moment(c.leftCareDate).isAfter(moment())
    );
    const archivedPlacements = cases.filter(
      c => c.leftCareDate && moment(c.leftCareDate).isBefore(moment())
    );
    return [
      <Card
        className="content-box"
        key="current"
        expanded={currentPlacementsExpanded}
        onExpandChange={() =>
          this.setState({
            currentPlacementsExpanded: !currentPlacementsExpanded,
          })
        }
      >
        <CardTitle
          subtitle="My Placements"
          actAsExpander
          showExpandableButton
        />
        {currentPlacements.map(c => (
          <PlacementCard key={c._id} caseLoad={c} expandable />
        ))}
      </Card>,
      <Card
        className="content-box"
        key="archived"
        expanded={archivedPlacementsExpanded}
        onExpandChange={() =>
          this.setState({
            archivedPlacementsExpanded: !archivedPlacementsExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Archived Placements"
          actAsExpander
          showExpandableButton
        />
        {archivedPlacements.map(c => (
          <PlacementCard key={c._id} caseLoad={c} expandable />
        ))}
      </Card>,
    ];
  }
  renderAttachments(readOnly) {
    const { attachmentsExpanded, household } = this.state;
    const { user } = this.props;
    return (
      <Card
        className="content-box"
        expanded={attachmentsExpanded}
        onExpandChange={() =>
          this.setState({ attachmentsExpanded: !attachmentsExpanded })
        }
      >
        <CardTitle subtitle="Attachments" actAsExpander showExpandableButton />
        <Attachment
          admin={
            user.roles.includes('admin') || user.roles.includes('moderator')
          }
          readOnly={readOnly}
          record={household._id}
          recordType="Household"
          expandable
        />
      </Card>
    );
  }
  renderLogs() {
    const { logs, logExpanded } = this.state;
    if (isEmpty(logs) && logExpanded) this.getLogs();
    return (
      <Card
        className="content-box"
        expanded={logExpanded}
        onExpandChange={() => this.setState({ logExpanded: !logExpanded })}
      >
        <CardTitle subtitle="Logs" actAsExpander showExpandableButton />
        {!isEmpty(logs) && <ObjectLogs logs={logs} expandable />}
      </Card>
    );
  }
  renderAccommodation() {
    const { household, accommodationExpanded } = this.state;
    const { accommodation } = household;
    return (
      <Card
        className="content-box"
        expanded={accommodationExpanded}
        onExpandChange={() =>
          this.setState({
            accommodationExpanded: !accommodationExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Accommodation"
          actAsExpander
          showExpandableButton
        />
        {accommodation.map(a => (
          <AccommodationCard
            readOnly
            accommodation={a}
            expandable
            key={a._id}
          />
        ))}
      </Card>
    );
  }
  renderStaff() {
    const { staff } = this.state.household;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Coordinator" />
        {!isEmpty(staff) && (
          <PersonCard person={staff} rel="staff" key={staff._id} />
        )}
      </Card>
    );
  }
  renderNoteSearch(num) {
    const { search } = this.state;
    return (
      <List expandable>
        <ListItem
          primaryTogglesNestedList
          primaryText={`Search Notes (${num})`}
          rightIcon={<icons.SearchIcon color={colours.skyBlue} />}
          nestedItems={[
            <ListItem disabled key="searchbody">
              <TextField
                param={{ field: 'text', name: 'Search', editable: true }}
                value={search.text}
                onUpdated={newVal => {
                  this.setState({ search: { ...search, text: newVal } });
                  this.doSearch();
                }}
              />
              {noteModel.map(n => {
                if (!n.filterable) return null;
                return (
                  <SelectField
                    search
                    key={n.field}
                    param={n}
                    value={search[n.field]}
                    onUpdated={newVal => {
                      this.setState({
                        search: { ...search, [n.field]: newVal },
                      });
                      this.doSearch();
                    }}
                  />
                );
              })}
            </ListItem>,
          ]}
        />
      </List>
    );
  }
  renderNotes(readOnly) {
    const { searchResults, notes, household, notesExpanded } = this.state;
    const displayNotes = searchResults || notes;
    return (
      <Card
        className="content-box"
        expanded={notesExpanded}
        onExpandChange={() => this.setState({ notesExpanded: !notesExpanded })}
      >
        <CardTitle subtitle="Notes" actAsExpander showExpandableButton />
        {this.renderNoteSearch(displayNotes ? displayNotes.length : null)}
        {displayNotes &&
          displayNotes.map(n => (
            <NoteCard
              readOnly={readOnly}
              key={n._id}
              note={n}
              household={household}
              expandable
            />
          ))}
      </Card>
    );
  }
  renderPrimaryContact(readOnly) {
    const { otherIndividuals } = this.props.household;
    const { primaryContactExpanded } = this.state;
    return (
      <Card
        className="content-box"
        expanded={primaryContactExpanded}
        onExpandChange={() =>
          this.setState({
            primaryContactExpanded: !primaryContactExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Primary Contact"
          actAsExpander
          showExpandableButton
        />
        {otherIndividuals.map(
          o =>
            o.otherType === 'primaryContact' && (
              <PersonCard
                expandable
                readOnly={readOnly}
                person={o}
                rel="other"
                key={o._id}
                select={() => this.selectMember(o)}
              />
            )
        )}
      </Card>
    );
  }
  renderEmergencyContact(readOnly) {
    const { otherIndividuals } = this.props.household;
    const { emergencyContactExpanded } = this.state;
    return (
      <Card
        className="content-box"
        expanded={emergencyContactExpanded}
        onExpandChange={() =>
          this.setState({
            emergencyContactExpanded: !emergencyContactExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Emergency Contact"
          actAsExpander
          showExpandableButton
        />
        {otherIndividuals.map(
          o =>
            o.otherType === 'emergencyContact' && (
              <PersonCard
                expandable
                readOnly={readOnly}
                person={o}
                rel="other"
                key={o._id}
                select={() => this.selectMember(o)}
              />
            )
        )}
      </Card>
    );
  }
  render() {
    const {
      household,
      householdType,
      showAssignCoord,
      showNoteAdd,
      speedDialOpen,
      showAddPerson,
      showAddSecondary,
      showMemberEdit,
      showPrintOptions,
      member,
      showDelPro,
      showRemPro,
      removeExtra,
      removePrimary,
      error,
    } = this.state;
    const { user, dispatch } = this.props;
    if (error) return <GlobalError />;
    if (isEmpty(household) || isEmpty(user.roles)) return <GlobalLoading />;
    let readOnly = true;
    if (!user.roles.includes('admin') && !user.roles.includes('moderator')) {
      // const households = user.staff.caseLoad.map(c => c.household._id);
      // if (households.includes(household._id)) readOnly = false;
      readOnly = false;
    } else readOnly = false;
    if (household.deleted)
      return (
        <div className="container">
          <div className="content">
            <div>
              <div
                className="list-search"
                style={{
                  margin: 'auto',
                  width: '50%',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                This household can not be found or has been deleted.
                <BackLink />
              </div>
            </div>
          </div>
        </div>
      );
    const deletable =
      user.roles.includes('admin') || user.roles.includes('moderator');
    const carerRemovable =
      deletable ||
      (user.roles.includes('coordinator') &&
        user.staff.caseLoad.some(c => c.household._id === household._id));
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{household.name} - HEART1869</title>
          <meta
            name="description"
            content="Kennerley HEART1869 Household Detail"
          />
        </Helmet>
        <div className="content">
          <div className="content-body">
            <div className="content-column">
              <Card className="content-box">
                <Title title={household.name} />
              </Card>
              {householdType !== 'Community' && this.renderDetails()}
              {householdType !== 'Community' &&
                this.renderCarers(carerRemovable)}
              {householdType !== 'Community' && this.renderPreviousCarers()}
              {householdType === 'Community' && this.renderPrimaryContact()}
              {householdType === 'Community' && this.renderEmergencyContact()}
              {this.renderHouseholdMembers(readOnly)}
              {this.renderPlacements()}
            </div>
            <div className="content-column">
              {householdType !== 'Community' && this.renderStaff()}
              {this.renderNotes(readOnly)}
              {householdType !== 'Community' && this.renderAccommodation()}
              {this.renderAttachments(readOnly)}
              {this.renderLogs()}
            </div>
          </div>
          <SpeedDial
            styleBackdrop={{ opacity: 0.01 }}
            className="speed-dial"
            isOpen={speedDialOpen}
            onChange={change => this.setState({ speedDialOpen: change.isOpen })}
          >
            <BubbleList className="bubble-list">
              {false && (
                <BubbleListItem
                  primaryText="Print"
                  rightAvatar={
                    <Avatar
                      backgroundColor={colours.limeGreen}
                      color={colours.skyBlue}
                      icon={<icons.PrintIcon />}
                    />
                  }
                  onClick={() => {
                    this.setState({ speedDialOpen: false });
                    window.open(
                      '/printpreview' + window.location.pathname,
                      '_blank',
                      'menubar=0,location=0',
                      false
                    );
                  }}
                />
              )}
              <BubbleListItem
                primaryText="Print"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.PrintIcon />}
                  />
                }
                onClick={() =>
                  this.setState({
                    speedDialOpen: false,
                    showPrintOptions: true,
                  })
                }
              />
              <BubbleListItem
                primaryText="Note"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.NoteIcon />}
                  />
                }
                onClick={() =>
                  this.setState({ speedDialOpen: false, showNoteAdd: true })
                }
              />
              {householdType !== 'Community' &&
                isEmpty(household.secondaryCarer) && (
                  <BubbleListItem
                    primaryText="Secondary Carer"
                    rightAvatar={
                      <Avatar
                        backgroundColor={colours.limeGreen}
                        color={colours.skyBlue}
                        icon={<icons.PersonIcon />}
                      />
                    }
                    onClick={() => {
                      this.setState({
                        speedDialOpen: false,
                        showAddSecondary: true,
                      });
                    }}
                  />
                )}
              <BubbleListItem
                primaryText="Household Member"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.CarerIcon />}
                  />
                }
                onClick={() => {
                  this.setState({ speedDialOpen: false, showAddPerson: true });
                }}
              />
              {householdType !== 'Community' &&
                deletable && (
                  <BubbleListItem
                    primaryText="Assign Coordinator"
                    rightAvatar={
                      <Avatar
                        backgroundColor={colours.limeGreen}
                        color={colours.skyBlue}
                        icon={<icons.AssignCoordinatorIcon />}
                      />
                    }
                    onClick={() =>
                      this.setState({
                        speedDialOpen: false,
                        showAssignCoord: true,
                      })
                    }
                  />
                )}
              {householdType !== 'Community' &&
                deletable && (
                  <BubbleListItem
                    primaryText="Delete"
                    rightAvatar={
                      <Avatar
                        backgroundColor={colours.kRed}
                        color="white"
                        icon={<icons.DeleteIcon />}
                      />
                    }
                    onClick={() => {
                      this.setState({ speedDialOpen: false, showDelPro: true });
                    }}
                  />
                )}
            </BubbleList>
          </SpeedDial>
          <PersonAdd
            closeDialog={() => this.setState({ showAddPerson: false })}
            open={showAddPerson}
            address={household.address}
            household={household}
            type="member"
          />
          <CarerAdd
            prospect={
              household.primaryCarer &&
              !isDate(household.primaryCarer.approvalDate)
            }
            closeDialog={() => this.setState({ showAddSecondary: false })}
            open={showAddSecondary}
            household={household}
          />
          <PersonEdit
            closeDialog={() =>
              this.setState({ showMemberEdit: false, member: '' })
            }
            open={showMemberEdit}
            other={member}
            title={`Edit ${member ? member.contact.fullName : ''}`}
            household={household}
          />
          <Assign
            closeDialog={() => this.setState({ showAssignCoord: false })}
            open={showAssignCoord}
            household={household}
            prevStaff={household.staff}
            dispatch={dispatch}
          />
          <NoteAdd
            closeDialog={() => this.setState({ showNoteAdd: false })}
            open={showNoteAdd}
            household={household}
            primaryCarer={household.primaryCarer}
            secondaryCarer={household.secondaryCarer}
            householdMembers={household.otherIndividuals}
            staff={user.staff}
          />
          <PrintOptions
            closeDialog={() => this.setState({ showPrintOptions: false })}
            open={showPrintOptions}
            household={household}
          />
          <DeletePrompt
            closeDialog={() => this.setState({ showDelPro: false })}
            open={showDelPro}
            delete={() => dispatch(deleteHousehold(household._id))}
            parentRoute={'/household'}
            extra="You will also delete all associated carers and household members."
          />
          <RemovePrompt
            closeDialog={() => this.setState({ showRemPro: false })}
            open={showRemPro}
            remove={() =>
              dispatch(removeCarerFromHousehold(household._id, removePrimary))
            }
            extra={removeExtra}
          />
        </div>
      </div>
    );
  }
}

HouseholdDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  household: state.household.detail,
  notes: state.household.detail.notes,
  user: state.user.profile,
});

export default connect(mapStateToProps)(HouseholdDetail);
