import getMuiTheme from 'material-ui/styles/getMuiTheme';

import ArchiveIcon from 'material-ui/svg-icons/content/archive';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import PersonIcon from 'material-ui/svg-icons/social/person';
import CarerIcon from 'material-ui/svg-icons/action/supervisor-account';
import YoungPersonIcon from 'material-ui/svg-icons/social/mood';
import StaffIcon from 'material-ui/svg-icons/communication/contacts';
import ProspectIcon from 'material-ui/svg-icons/notification/phone-in-talk';
import SearchIcon from 'material-ui/svg-icons/action/search';
import ClearIcon from 'material-ui/svg-icons/action/highlight-off';
import DashboardIcon from 'material-ui/svg-icons/action/dashboard';
import PlacementIcon from 'material-ui/svg-icons/places/business-center';
import HomeIcon from 'material-ui/svg-icons/action/home';
import ReferralIcon from 'material-ui/svg-icons/content/inbox';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import LogIcon from 'material-ui/svg-icons/action/subject';
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import EventIcon from 'material-ui/svg-icons/notification/event-note';
import NoteIcon from 'material-ui/svg-icons/av/library-books';
import AddIcon from 'material-ui/svg-icons/content/add';
import AccommodationIcon from 'material-ui/svg-icons/maps/local-hotel';
import EditIcon from 'material-ui/svg-icons/image/edit';
import CancelIcon from 'material-ui/svg-icons/navigation/cancel';
import ExitToAppIcon from 'material-ui/svg-icons/action/exit-to-app';
import DownArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import SelectedIcon from 'material-ui/svg-icons/navigation/check';
import LockIcon from 'material-ui/svg-icons/action/lock';
import TrainingIcon from 'material-ui/svg-icons/action/assignment-ind';
import FamilyIcon from 'material-ui/svg-icons/action/group-work';
import EducationIcon from 'material-ui/svg-icons/social/school';
import MedicalIcon from 'material-ui/svg-icons/maps/local-hospital';
import WorkIcon from 'material-ui/svg-icons/action/work';
import LegalIcon from 'material-ui/svg-icons/action/gavel';
import IncidentIcon from 'material-ui/svg-icons/content/report';
import AttachmentIcon from 'material-ui/svg-icons/editor/attach-file';
import AssignCoordinatorIcon from 'material-ui/svg-icons/maps/person-pin';
import MeIcon from 'material-ui/svg-icons/action/account-circle';
import ReviewIcon from 'material-ui/svg-icons/action/assignment';
import VisitIcon from 'material-ui/svg-icons/maps/place';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import HeartIcon from 'material-ui/svg-icons/action/favorite';
import ChevronRightIcon from 'material-ui/svg-icons/navigation/chevron-right';
import ChevronLeftIcon from 'material-ui/svg-icons/navigation/chevron-left';
import AlertIcon from 'material-ui/svg-icons/alert/warning';
import StatsIcon from 'material-ui/svg-icons/action/trending-up';
import SiblingIcon from 'material-ui/svg-icons/social/sentiment-satisfied';
import ParentIcon from 'material-ui/svg-icons/social/people';
import InterestsIcon from 'material-ui/svg-icons/av/movie';
import TherapeuticSupportIcon from 'material-ui/svg-icons/action/loyalty';
import CommunityRespiteIcon from 'material-ui/svg-icons/social/people-outline';
import CheckBoxIcon from 'material-ui/svg-icons/toggle/check-box';
import CheckBoxOutlineBlankIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank';
import StoreIcon from 'material-ui/svg-icons/action/store';
import BusinessIcon from 'material-ui/svg-icons/communication/business';
import LocationCityIcon from 'material-ui/svg-icons/social/location-city';
import FavoriteIcon from 'material-ui/svg-icons/action/favorite-border';
import PrintIcon from 'material-ui/svg-icons/action/print';
import AssessmentIcon from 'material-ui/svg-icons/action/assessment';
import RemoveIcon from 'material-ui/svg-icons/content/remove-circle';
import FormIcon from 'material-ui/svg-icons/editor/format-list-bulleted';
import HistoryIcon from 'material-ui/svg-icons/action/history';

import { white } from 'material-ui/styles/colors';

export const colours = {
  limeGreen: 'rgb(227,228,44)',
  skyBlue: 'rgb(34,170,225)',
  lightSkyBlue: 'rgb(60, 199, 244)',
  steelBlue: 'rgb(30,136,180)',
  kRed: 'rgb(231,76,60)',
  paleBlue: 'rgb(170, 225, 250)',
  paleLimeGreen: 'rgb(245,243,160)',
  kGrey: 'rgb(147,149,152)',
  darkBlack: 'rgb(62,62,63)',
  brightGreen: 'rgb(28,248,37)',
  orange: 'rgb(238,206,27)',
};

export const icons = {
  ArchiveIcon,
  CloseIcon,
  PersonIcon, // Other Individual
  CarerIcon,
  YoungPersonIcon,
  StaffIcon,
  ProspectIcon,
  SearchIcon,
  ClearIcon,
  DashboardIcon,
  PlacementIcon,
  HomeIcon,
  ReferralIcon,
  SettingsIcon,
  LogIcon,
  MenuIcon,
  EventIcon,
  NoteIcon,
  AddIcon,
  AccommodationIcon,
  EditIcon,
  CancelIcon,
  ExitToAppIcon,
  DownArrow,
  SelectedIcon,
  LockIcon,
  TrainingIcon,
  FamilyIcon,
  EducationIcon,
  MedicalIcon,
  WorkIcon,
  LegalIcon,
  IncidentIcon,
  AttachmentIcon,
  AssignCoordinatorIcon,
  MeIcon,
  ReviewIcon,
  VisitIcon,
  MoreVertIcon,
  DeleteIcon,
  HeartIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  AlertIcon,
  StatsIcon,
  SiblingIcon,
  ParentIcon,
  InterestsIcon,
  TherapeuticSupportIcon,
  CommunityRespiteIcon,
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  StoreIcon,
  BusinessIcon,
  LocationCityIcon,
  FavoriteIcon,
  PrintIcon,
  AssessmentIcon,
  RemoveIcon,
  FormIcon,
  HistoryIcon,
};

// https://github.com/callemall/material-ui/blob/master/src/styles/getMuiTheme.js#L31
// {
//   spacing: spacing,
//   fontFamily: 'Roboto, sans-serif',
//   palette: {
//     primary1Color: cyan500,
//     primary2Color: cyan700,
//     primary3Color: grey400,
//     accent1Color: pinkA200,
//     accent2Color: grey100,
//     accent3Color: grey500,
//     textColor: darkBlack,
//     alternateTextColor: white,
//     canvasColor: white,
//     borderColor: grey300,
//     disabledColor: fade(darkBlack, 0.3),
//     pickerHeaderColor: cyan500,
//     clockCircleColor: fade(darkBlack, 0.07),
//     shadowColor: fullBlack,
//   },
// };

const zIndex = {
  zIndex: {
    snackbar: 2300,
    dialog: 900,
    dialogOverlay: 800,
    drawerOverlay: 600,
    drawer: 600,
    appBar: 700,
  },
};

export const Theme = getMuiTheme(
  {
    palette: {
      primary1Color: colours.skyBlue,
      primary2Color: colours.steelBlue,
      accent1Color: colours.limeGreen,
      textColor: colours.darkBlack,
      alternateTextColor: white,
      disabledColor: colours.kGrey,
      pickerHeaderColor: colours.skyBlue,
    },
    appBar: {
      color: colours.skyBlue,
      textColor: colours.limeGreen,
    },
    button: {
      textTransform: 'none',
    },
    tabs: {
      textColor: white,
      selectedTextColor: colours.limeGreen,
    },
    textField: {
      errorColor: colours.kRed,
    },
    menuItem: {
      selectedTextColor: colours.skyBlue,
    },
    listItem: {
      leftIconColor: colours.skyBlue,
      primaryTextColor: 'white',
      rightIconColor: 'white',
    },
    drawer: {
      color: colours.skyBlue,
    },
  },
  zIndex
);
