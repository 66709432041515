import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import each from 'lodash/each';
import get from 'lodash/get';

import { ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import { colours, icons } from '../theme';

/*
  TODO
  Different icons for different people types
  Status
*/

class PersonCard extends Component {
  renderStatus() {
    const { person, rel } = this.props;
    const {
      checksRequiredFrom,
      wwvpCheck,
      pcResults,
      cscResults,
      approvalDate,
      status,
      dateClosed,
      dateInactive,
      otherType,
      wwvpcExpiry,
      scocExpiry,
      pcReviewDate,
      reviewDue,
      cases,
      household,
    } = person;
    let active = person.active || false;
    const now = new Date();
    const next2month = new Date();
    next2month.setMonth(next2month.getMonth() + 2);
    switch (rel) {
      case 'other':
        if (otherType === 'member') {
          if (
            new Date(checksRequiredFrom) <= now &&
            (!wwvpCheck ||
              !pcResults ||
              !cscResults ||
              new Date(pcReviewDate) <= now ||
              new Date(wwvpcExpiry) <= now)
          ) {
            return colours.kRed;
          }
          if (
            new Date(checksRequiredFrom) <= next2month &&
            new Date(wwvpcExpiry) <= next2month
          ) {
            return colours.orange;
          }
        }
        if (otherType === 'sibling') return colours.kGrey;
        return colours.skyBlue;
      case 'carer':
      case 'prospect':
        if (
          (dateClosed || dateInactive) &&
          (status === 'Temporarily Inactive' ||
            status === 'Withdrawn' ||
            status === 'Not Suitable' ||
            status === 'Transferred' ||
            status === 'Deregistered' ||
            status === 'Closed')
        )
          return colours.kGrey;
        if (
          get(household, 'careType', []).includes('Family Connections Program')
        ) {
          return colours.brightGreen;
        }
        if (
          !wwvpCheck ||
          !pcResults ||
          !cscResults ||
          !wwvpcExpiry ||
          !scocExpiry ||
          !reviewDue ||
          new Date(pcReviewDate) <= now ||
          new Date(wwvpcExpiry) <= now ||
          new Date(reviewDue) <= now ||
          new Date(scocExpiry) <= now
        ) {
          return colours.kRed;
        }
        if (
          new Date(pcReviewDate) <= next2month ||
          new Date(wwvpcExpiry) <= next2month ||
          new Date(reviewDue) <= next2month ||
          new Date(scocExpiry) <= next2month
        ) {
          return colours.orange;
        }
        if (approvalDate) return colours.brightGreen;
        return colours.skyBlue;
      case 'young':
        each(cases, c => {
          const entryDate = new Date(c.kennerleyEntryDate);
          const leftDate = new Date(c.leftCareDate);
          if (
            entryDate <= new Date() &&
            (!c.leftCareDate || leftDate > new Date())
          )
            active = true;
        });
        if (!active) return colours.kGrey;
        return colours.skyBlue;
      case 'staff':
        if (
          !active ||
          (!person.admin && !person.coordinator && !person.student)
        )
          return colours.kGrey;
        if (
          !wwvpCheck ||
          !pcResults ||
          !cscResults ||
          !wwvpcExpiry ||
          !scocExpiry ||
          new Date(wwvpcExpiry) <= now ||
          !pcReviewDate ||
          new Date(pcReviewDate) <= now ||
          !scocExpiry ||
          new Date(scocExpiry) <= now
        ) {
          return colours.kRed;
        }
        if (
          new Date(wwvpcExpiry) <= next2month ||
          new Date(pcReviewDate) <= next2month ||
          new Date(scocExpiry) <= next2month
        ) {
          return colours.orange;
        }
        return colours.skyBlue;
      default:
        return colours.skyBlue;
    }
  }
  renderAddress() {
    const { addr } = this.props;
    const addrStr = `${addr.subpremise ? `${addr.subpremise}/` : ''}${
      addr.street_number
    } ${addr.route}, ${addr.locality}`;
    return addrStr;
  }
  renderSubtitle() {
    const { person, caller } = this.props;
    if (caller === 'dashboard') {
      return `Coordinator: ${get(
        person,
        'household.staff.contact.fullName',
        'None'
      )}`;
    }
    const subParts = [];
    if (person.description) return person.description;
    if (person.title) {
      if (person.admin) subParts.push('Admin');
      if (person.coordinator) subParts.push('Coordinator');
      if (subParts.length) return `${person.title} (${subParts.join(', ')})`;
      return person.title;
    }
    if (person.contact.email) subParts.push(person.contact.email);
    if (person.contact.mobile) subParts.push(person.contact.mobile);
    else if (person.contact.phone) subParts.push(person.contact.phone);
    if (person.contact.userType === 'young' && subParts.length < 2) {
      let addr = person.contact.postalAddress;
      if (person.contact.residentialAddress)
        addr = person.contact.residentialAddress;
      if (addr.route)
        subParts.push(
          `${addr.subpremise ? `${addr.subpremise}/` : ''}${
            addr.street_number
          } ${addr.route}, ${addr.locality}`
        );
    }
    return subParts.join(', ');
  }
  render() {
    const {
      person,
      rel,
      title,
      selected,
      select,
      user,
      readOnly,
      householdLink,
      action,
    } = this.props;
    let { fullName } = person.contact;
    if (!fullName)
      fullName = `${person.contact.givenName} ${person.contact.surname}`;
    const colour = this.renderStatus();
    const iconCommons = {
      className: 'person-card-back',
      color: colour,
    };

    const iconsWithAttributes = {
      carer: <icons.CarerIcon {...iconCommons} />,
      prospect: <icons.ProspectIcon {...iconCommons} />,
      young: <icons.YoungPersonIcon {...iconCommons} />,
      person: <icons.PersonIcon {...iconCommons} />,
      staff: <icons.StaffIcon {...iconCommons} />,
      member:
        new Date(person.checksRequiredFrom) <= new Date() ? (
          <icons.PersonIcon {...iconCommons} />
        ) : (
          <icons.YoungPersonIcon {...iconCommons} />
        ),
      parent: <icons.ParentIcon {...iconCommons} />,
      doctor: <icons.MedicalIcon {...iconCommons} />,
      sibling: <icons.SiblingIcon {...iconCommons} />,
    };
    const useLink =
      !selected &&
      !select &&
      rel !== 'other' &&
      (rel !== 'staff' ||
        user.roles.includes('admin') ||
        user.roles.includes('moderator'));
    const disabled =
      (selected && !select) ||
      (rel === 'staff' &&
        !user.roles.includes('admin') &&
        !user.roles.includes('moderator'));
    return (
      <div className="person-card">
        {iconsWithAttributes[rel === 'other' ? person.otherType : rel]}
        <ListItem
          primaryText={`${fullName}${title ? ` (${title})` : ''}`}
          secondaryText={this.renderSubtitle()}
          leftAvatar={
            <Avatar backgroundColor={colours.kGrey}>
              {fullName.charAt(0)}
            </Avatar>
          }
          style={{ borderLeft: `5px solid ${colour}` }}
          containerElement={
            useLink || householdLink ? (
              householdLink || <Link to={`/${rel}/${person._id}`} />
            ) : (
              <span />
            )
          }
          disabled={readOnly || disabled}
          onClick={select ? () => select() : null}
          rightIconButton={action}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.profile,
});

export default connect(mapStateToProps)(PersonCard);
