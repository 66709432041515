import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { fetchCarer, fetchCarerNotes } from '../CarerActions';
import GlobalLoading from '../../../components/GlobalLoading';

import BackLink from '../../../components/BackLink';
import deepCompare from '../../../util/deepCompare';

import '../../../styles/PrintPreview.css';
import '../../../styles/PrintPDF.css';

import qs from 'query-string';

class CarerDetail extends Component {
  constructor(props) {
    super(props);

    const query = qs.parse(props.location.search);

    this.state = {
      user: {},
      carer: {},
      contact: {},
      household: {},
      training: {},
      logs: [],
      printContact: query.contact !== '0',
      printDetails: query.details !== '0',
      printRegulationOfCare: query.regulationOfCare !== '0',
      printInitialEnquiry: query.initialEnquiry !== '0',
      printAssessmentAndTraining: query.assessmentAndTraining !== '0',
      printFamily: query.family !== '0',
      printPlacements: query.placements !== '0',
      printSupport: query.support !== '0',
      printNotes: query.caseNotes !== '0',
      pdf: query.do === 'pdf',
    };

    props.dispatch(fetchCarer(props.match.params.id)).then(() => {
      props.dispatch(fetchCarerNotes(props.match.params.id));
    });
  }

  shouldComponentUpdate(props, state) {
    const { user, carer, contact, household } = this.props;
    return (
      !deepCompare(state, this.state) ||
      !deepCompare(props.user, user) ||
      !deepCompare(props.carer, carer) ||
      !deepCompare(props.contact, contact) ||
      !deepCompare(props.household, household)
    );
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ carer: props.carer, contact: props.contact });
  }

  renderPrintHeader_PP(bla) {
    const { pdf, contact } = this.state;
    return (
      <table className={!pdf ? 'printPreviewHeader' : 'printPDFHeader'}>
        <tbody>
          <tr>
            <td colSpan={1}>CARER</td>
            <td colSpan={1}>{contact && contact.fullName}</td>
            <td colSpan={1}>HEART1869&nbsp;</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderContactDetails_PP(bla) {
    const { pdf, contact } = this.state;
    return (
      contact && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Contact Details</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldValue" colSpan={3}>
                {contact.givenName + ' ' + contact.surname}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Also Knows As
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.aka}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Gender
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.gender}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Date of Birth
              </td>
              <td className="fieldValue" colSpan={1}>
                {moment(contact.birthDate).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Email
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.email}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Mobile Number
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.mobile}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Phone Number
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.phone}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Residential Address
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.residentialAddress.administrative_area_level_1 +
                  ', ' +
                  contact.residentialAddress.country}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Postal Address
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.postalAddress.administrative_area_level_1 +
                  ', ' +
                  contact.postalAddress.country}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderCarerDetails_PP(bla) {
    const { pdf, carer } = this.state;
    return (
      carer && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={5}>My Details</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Status
              </td>
              <td className="fieldTitle" colSpan={1}>
                Date Approved
              </td>
              <td className="fieldTitle" colSpan={1}>
                Date Added
              </td>
              <td className="fieldTitle" colSpan={1}>
                Date Closed
              </td>
              <td className="fieldTitle" colSpan={1}>
                File Number
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldValue" colSpan={1}>
                {carer.status}
              </td>
              <td className="fieldValue" colSpan={1}>
                {moment(carer.approvalDate).format('DD MMM YYYY')}
              </td>
              <td className="fieldValue" colSpan={1}>
                {moment(carer.created).format('DD MMM YYYY')}
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.dateClosed &&
                  moment(carer.dateClosed).format('DD MMM YYYY')}
              </td>
              <td className="fieldValue" colSpan={1}>
                {carer.fileNumber}
              </td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                First Nations Connection
              </td>
              <td className="fieldValue" colSpan={1}>
                {carer.aboriginal}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderRegulationOfCare_PP(bla) {
    const { pdf, carer } = this.state;
    return (
      carer && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Regulation of Care</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Review Date
              </td>
              <td className="fieldValue" colSpan={3}>
                {!!carer.reviewDue &&
                  moment(carer.reviewDue).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={3}>
                Working with Vulnerable People Check
              </td>
              <td className="fieldTitle" colSpan={1}>
                Expiry of WWVP Check
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldValue" colSpan={3}>
                {carer.wwvpCheck}
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.wwvpcExpiry &&
                  moment(carer.wwvpcExpiry).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Police Check Lodged
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.pcLodged &&
                  moment(carer.pcLodged).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Police Check Received
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.pcResultsReceived &&
                  moment(carer.pcResultsReceived).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={3}>
                Police Check Results
              </td>
              <td className="fieldTitle" colSpan={1}>
                Next PC Due
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldValue" colSpan={3}>
                {carer.pcResults}
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.pcReviewDate &&
                  moment(carer.pcReviewDate).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Child Safety Check Sent
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.cscSent && moment(carer.cscSent).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Child Safety Check Received
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.cscResultsReceived &&
                  moment(carer.cscResultsReceived).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Child Safety Check Results
              </td>
              <td className="fieldValue" colSpan={3}>
                {carer.cscResults}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderInitialEnquiry_PP(blah) {
    const { pdf, carer } = this.state;
    return (
      carer && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Initial Enquiry</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                How did they hear about Kennerley?
              </td>
              <td className="fieldValue" colSpan={1}>
                {carer.howDidTheyHear}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Suggested Care Type
              </td>
              <td className="fieldValue" colSpan={1}>
                {carer.enquiryCareType}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Date of Enquiry
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.enquiryDate &&
                  moment(carer.enquiryDate).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Date Information Set
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.infoSent &&
                  moment(carer.infoSent).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Registration of Interest Received
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.interestReceived &&
                  moment(carer.interestReceived).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Date of First Visit
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!carer.firstVisit &&
                  moment(carer.firstVisit).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Enquiry Comments
              </td>
              <td className="fieldValue" colSpan={3}>
                {carer.enquiryComments}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderFamily_PP() {
    const { pdf, carer } = this.state;
    const household = carer.household;
    return (
      !!household && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>My Family</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldValue" colSpan={1}>
                {household.name}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Address
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!household.address && household.address.street_number
                  ? household.address.street_number
                  : ''}
                {!!household.address && household.address.route
                  ? ', ' + household.address.route
                  : ''}
                {!!household.address && household.address.postal_town
                  ? ', ' + household.address.postal_town
                  : ''}
                {!!household.address &&
                household.address.administrative_area_level_2
                  ? ', ' + household.address.administrative_area_level_2
                  : ''}
                {!!household.address &&
                household.address.administrative_area_level_1
                  ? ', ' + household.address.administrative_area_level_1
                  : ''}
                {!!household.address && household.address.postal_code
                  ? ', ' + household.address.postal_code
                  : ''}
                {!!household.address && household.address.country
                  ? ', ' + household.address.country
                  : ''}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderAssessmentAndTraining_PP() {
    const { pdf, carer } = this.state;
    const training = carer.training;
    return (
      !isEmpty(training) && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>My Assessments and Training</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Module
              </td>
              <td className="fieldTitle" colSpan={1}>
                Training Started
              </td>
              <td className="fieldTitle" colSpan={1}>
                Training Completed
              </td>
              <td className="fieldTitle" colSpan={1}>
                Comments
              </td>
            </tr>

            {training.map(t => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {t.module}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {!!t.startDate && moment(t.startDate).format('DD MMM YYYY')}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {!!t.endDate && moment(t.endDate).format('DD MMM YYYY')}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {t.comments}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderPlacements_PP() {
    const { pdf, carer } = this.state;
    return (
      !isEmpty(carer.household.cases) && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>My Placements</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                Household
              </td>
              <td className="fieldTitle" colSpan={2}>
                From
              </td>
            </tr>
            {carer.household.cases.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td colSpan={1}>{o.youngPerson.contact.fullName}</td>
                <td colSpan={1}>
                  {o.household ? o.household.name : 'Moving On Program'}
                </td>
                <td colSpan={2}>
                  {moment(o.kennerleyEntryDate || o.created).format(
                    'DD MMM YYYY'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSubtitle(staff) {
    const subParts = [];
    if (staff.description) return staff.description;
    if (staff.title) {
      if (staff.admin) subParts.push('Admin');
      if (staff.coordinator) subParts.push('Coordinator');
      if (subParts.length) return `${staff.title} (${subParts.join(', ')})`;
      return staff.title;
    }
    if (staff.contact.email) subParts.push(staff.contact.email);
    if (staff.contact.mobile) subParts.push(staff.contact.mobile);
    else if (staff.contact.phone) subParts.push(staff.contact.phone);
    if (staff.contact.userType === 'young' && subParts.length < 2) {
      let addr = staff.contact.postalAddress;
      if (staff.contact.residentialAddress)
        addr = staff.contact.residentialAddress;
      if (addr.route)
        subParts.push(`${addr.street_number} ${addr.route}, ${addr.locality}`);
    }
    return subParts.join(', ');
  }

  renderSupport_PP() {
    const { pdf, carer } = this.state;

    let staff = [];
    if (!isEmpty(carer.household))
      staff = uniqBy(carer.household.cases.map(c => c.staff), '_id');

    return (
      !isEmpty(staff) && (
        <table
          className={
            !pdf ? 'printPreviewTable ypNotes' : 'printPDFTable ypNotes'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={2}>My Support</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                Title
              </td>
            </tr>

            {staff.map(s => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {s.contact.fullName
                    ? s.contact.fullName
                    : s.contact.givenName + ' ' + s.contact.surname}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {this.renderSubtitle(s)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderNotes_PP() {
    const { pdf, carer } = this.state;
    const notes = carer.notes;
    return (
      !isEmpty(notes) && (
        <table
          className={
            !pdf ? 'printPreviewTable ypNotes' : 'printPDFTable ypNotes'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={12}>Notes</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Date Recorded
              </td>
              <td className="fieldTitle" colSpan={1}>
                Title
              </td>
              <td className="fieldTitle" colSpan={1}>
                Category
              </td>
              <td className="fieldTitle" colSpan={1}>
                Communication
              </td>
              <td className="fieldTitle" colSpan={1}>
                Home Visit
              </td>
              <td className="fieldTitle" colSpan={1}>
                Incident Reporting
              </td>
              <td className="fieldTitle" colSpan={1}>
                Concern In Care
              </td>
              <td className="fieldTitle" colSpan={1}>
                Quality of Care
              </td>
              <td className="fieldTitle" colSpan={1}>
                People
              </td>
              <td className="fieldTitle" colSpan={3}>
                Note
              </td>
            </tr>
            {notes.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {moment(o.dateRecorded).format('DD MMM YYYY')}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.title}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.category}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.communication}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.homeVisitMonth + ' ' + o.homeVisitYear}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.incidentReporting}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.concernInCare ? 'Yes' : 'No'}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.qualityOfCare ? 'Yes' : 'No'}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {!!o.staff && o.staff.contact && o.staff.contact.fullName}
                  {!isEmpty(o.carers) &&
                    o.carers.map(c => ', ' + c.contact.fullName)}
                </td>
                <td className="fieldValue" colSpan={3}>
                  {o.note}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  render() {
    const { pdf, carer, contact } = this.state;
    // let readOnly = true;
    const { user } = this.props;
    if (isEmpty(carer) || isEmpty(user.roles)) return <GlobalLoading />;
    if (carer.deleted)
      return (
        <div className="container">
          <div className="content">
            <div className="list-head">
              <div
                className="list-search"
                style={{
                  minHeight: '100px',
                  margin: 'auto',
                  width: '50%',
                  textAlign: 'center',
                }}
              >
                This carer can not be found or has been deleted.
                <BackLink />
              </div>
            </div>
          </div>
        </div>
      );

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {contact ? contact.fullName : 'Young Person'} - HEART1869
          </title>
          <meta
            name="description"
            content="Kennerley HEART1869 Young Person Detail"
          />
        </Helmet>
        <div className="content">
          <div className="content-body">
            <div className={!pdf ? 'printPreview' : 'printPDF'}>
              {this.renderPrintHeader_PP()}
              {this.state.printContact && this.renderContactDetails_PP()}
              {this.state.printDetails && this.renderCarerDetails_PP()}
              {this.state.printRegulationOfCare &&
                this.renderRegulationOfCare_PP()}
              {this.state.printInitialEnquiry && this.renderInitialEnquiry_PP()}
              {this.state.printAssessmentAndTraining &&
                this.renderAssessmentAndTraining_PP()}
              {this.state.printFamily && this.renderFamily_PP()}

              {this.state.printPlacements && this.renderPlacements_PP()}

              {this.state.printSupport && this.renderSupport_PP()}

              {this.state.printNotes && this.renderNotes_PP()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CarerDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.profile,
  carer: state.carer.detail,
  contact: state.carer.detail.contact,
  household: state.carer.detail.household,
});

export default connect(mapStateToProps)(CarerDetail);
