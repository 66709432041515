import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';

import { fetchHousehold, fetchHouseholdNotes } from '../HouseholdActions';

import GlobalLoading from '../../../components/GlobalLoading';
import BackLink from '../../../components/BackLink';

import deepCompare from '../../../util/deepCompare';

import '../../../styles/PrintPreview.css';
import '../../../styles/PrintPDF.css';

import qs from 'query-string';

class HouseholdDetail extends Component {
  constructor(props) {
    super(props);

    const query = qs.parse(props.location.search);

    this.state = {
      household: {},
      notes: [],
      printHousehold: query.household !== '0',
      printOtherHouseholdMembers: query.otherHouseholdMembers !== '0',
      printCarers: query.carers !== '0',
      printCoordinator: query.coordinator !== '0',
      printPlacements: query.placements !== '0',
      printAccommodation: query.accommodation !== '0',
      printNotes: query.notes !== '0',
      pdf: query.do === 'pdf',
    };

    props.dispatch(fetchHousehold(props.match.params.id, 'print'));
  }

  shouldComponentUpdate(props, state) {
    const { user, household, notes } = this.props;
    return (
      !deepCompare(state, this.state) ||
      !deepCompare(props.user, user) ||
      !deepCompare(props.household, household) ||
      !deepCompare(props.notes, notes)
    );
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { match } = this.props;
    if (!isEmpty(props.household) && !props.notes) {
      props.dispatch(fetchHouseholdNotes(match.params.id));
    }
    if (props.notes) {
      this.setState({ notes: props.notes });
    }
    if (!isEmpty(props.household)) {
      this.setState({ household: props.household });
    }
  }

  renderPrintHeader_PP(bla) {
    const { pdf, household } = this.state;
    return (
      <table className={!pdf ? 'printPreviewHeader' : 'printPDFHeader'}>
        <tbody>
          <tr>
            <td colSpan={1}>HOUSEHOLD</td>
            <td colSpan={1}>{household.name}</td>
            <td colSpan={1}>HEART1869&nbsp;</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderMyDetails_PP(bla) {
    const { pdf, household } = this.state;
    return (
      !!household && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Household Details</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name:
              </td>
              <td className="fieldValue" colSpan={1}>
                {household.name}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Type of Care
              </td>
              <td className="fieldValue" colSpan={1}>
                {household.careType}
              </td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Kinship
              </td>
              <td className="fieldValue" colSpan={1}>
                {household.kinship ? 'Yes' : 'No'}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Services Provided
              </td>
              <td className="fieldValue" colSpan={1}>
                {household.servicesProvided}
              </td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Foster Capacity
              </td>
              <td className="fieldValue" colSpan={1}>
                {household.fosterCapacity}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Respite Capacity
              </td>
              <td className="fieldValue" colSpan={1}>
                {household.respiteCapacity}
              </td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Placements Restrictions and Conditions
              </td>
              <td className="fieldValue" colSpan={3}>
                {household.restrictions}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderCarers_PP() {
    const { pdf } = this.state;
    const { primaryCarer, secondaryCarer } = this.props.household;
    return (
      (primaryCarer || secondaryCarer) && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={6}>Carers</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                Primary/Secondary
              </td>
              <td className="fieldTitle" colSpan={1}>
                Email
              </td>
              <td className="fieldTitle" colSpan={1}>
                Phone
              </td>
              <td className="fieldTitle" colSpan={2}>
                Address
              </td>
            </tr>
            {primaryCarer && (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {primaryCarer.contact.fullName}
                </td>
                <td className="fieldValue" colSpan={1}>
                  Primary
                </td>
                <td className="fieldValue" colSpan={1}>
                  {primaryCarer.contact.email}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {!!primaryCarer.contact.mobile && !!primaryCarer.contact.phone
                    ? primaryCarer.contact.mobile +
                      ' / ' +
                      primaryCarer.contact.phone
                    : primaryCarer.contact.mobile
                      ? primaryCarer.contact.mobile
                      : primaryCarer.contact.phone
                        ? primaryCarer.contact.phone
                        : ''}
                </td>
                <td className="fieldValue" colSpan={2}>
                  {primaryCarer.contact.residentialAddress
                    .administrative_area_level_1 +
                    ', ' +
                    primaryCarer.contact.residentialAddress.country}
                </td>
              </tr>
            )}
            {secondaryCarer && (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {secondaryCarer.contact.fullName}
                </td>
                <td className="fieldValue" colSpan={1}>
                  Secondary
                </td>
                <td className="fieldValue" colSpan={1}>
                  {primaryCarer.contact.email}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {!!primaryCarer.contact.mobile && !!primaryCarer.contact.phone
                    ? primaryCarer.contact.mobile +
                      ' /' +
                      primaryCarer.contact.phone
                    : primaryCarer.contact.mobile
                      ? primaryCarer.contact.mobile
                      : primaryCarer.contact.phone
                        ? primaryCarer.contact.phone
                        : ''}
                </td>
                <td className="fieldValue" colSpan={2}>
                  {primaryCarer.contact.residentialAddress
                    .administrative_area_level_1 +
                    ', ' +
                    primaryCarer.contact.residentialAddress.country}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )
    );
  }

  renderHouseholdMembers_PP() {
    const { pdf } = this.state;
    const { otherIndividuals } = this.props.household;
    return (
      !isEmpty(otherIndividuals) && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={6}>Other Household Members</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                Date of Birth
              </td>
              <td className="fieldTitle" colSpan={1}>
                Email
              </td>
              <td className="fieldTitle" colSpan={1}>
                Phone
              </td>
              <td className="fieldTitle" colSpan={2}>
                Address
              </td>
            </tr>
            {otherIndividuals.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {o.contact.fullName}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.contact.birthDate).format('DD MMM YYYY')}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.contact.email}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {!!o.contact.mobile && !!o.contact.phone
                    ? o.contact.mobile + ' /' + o.contact.phone
                    : o.contact.mobile
                      ? o.contact.mobile
                      : o.contact.phone
                        ? o.contact.phone
                        : ''}
                </td>
                <td className="fieldValue" colSpan={2}>
                  {o.contact.residentialAddress.administrative_area_level_1 +
                    ' ' +
                    o.contact.residentialAddress.country}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderCoordinator_PP() {
    const { pdf } = this.state;
    const { staff } = this.state.household;
    return (
      staff && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={6}>Coordinator</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                Title
              </td>
              <td className="fieldTitle" colSpan={1}>
                Email
              </td>
              <td className="fieldTitle" colSpan={1}>
                Phone
              </td>
              <td className="fieldTitle" colSpan={2}>
                Address
              </td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldValue" colSpan={1}>
                {staff.contact.fullName}
              </td>
              <td className="fieldValue" colSpan={1}>
                {staff.title}
              </td>
              <td className="fieldValue" colSpan={1}>
                {staff.contact.email}
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!staff.contact.mobile && !!staff.contact.phone
                  ? staff.contact.mobile + ' /' + staff.contact.phone
                  : staff.contact.mobile
                    ? staff.contact.mobile
                    : staff.contact.phone
                      ? staff.contact.phone
                      : ''}
              </td>
              <td className="fieldValue" colSpan={2}>
                {staff.contact.residentialAddress
                  ? staff.contact.residentialAddress
                      .administrative_area_level_1 +
                    ' ' +
                    staff.contact.residentialAddress.country
                  : ''}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderPlacements_PP() {
    const { pdf, household } = this.state;
    return (
      !isEmpty(household.cases) && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Placements</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                Household
              </td>
              <td className="fieldTitle" colSpan={2}>
                From
              </td>
            </tr>
            {household.cases.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td colSpan={1}>{o.youngPerson.contact.fullName}</td>
                <td colSpan={1}>
                  {o.household ? o.household.name : 'Moving On Program'}
                </td>
                <td colSpan={2}>
                  {moment(o.kennerleyEntryDate || o.created).format(
                    'DD MMM YYYY'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderNotes_PP() {
    const { pdf, notes } = this.state;
    return (
      !isEmpty(notes) && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={12}>Notes</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Date Recorded
              </td>
              <td className="fieldTitle" colSpan={1}>
                Title
              </td>
              <td className="fieldTitle" colSpan={1}>
                Category
              </td>
              <td className="fieldTitle" colSpan={1}>
                Communication
              </td>
              <td className="fieldTitle" colSpan={1}>
                Home Visit
              </td>
              <td className="fieldTitle" colSpan={1}>
                Incident Reporting
              </td>
              <td className="fieldTitle" colSpan={1}>
                Concern In Care
              </td>
              <td className="fieldTitle" colSpan={1}>
                Quality of Care
              </td>
              <td className="fieldTitle" colSpan={1}>
                People
              </td>
              <td className="fieldTitle" colSpan={3}>
                Note
              </td>
            </tr>
            {notes.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {moment(o.dateRecorded).format('DD MMM YYYY')}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.title}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.category}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.communication}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.homeVisitMonth + ' ' + o.homeVisitYear}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.incidentReporting}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.concernInCare ? 'Yes' : 'No'}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.qualityOfCare ? 'Yes' : 'No'}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {!!o.staff && o.staff.contact.fullName}
                  {!isEmpty(o.carers) &&
                    o.carers.map(c => ', ' + c.contact.fullName)}
                </td>
                <td className="fieldValue" colSpan={3}>
                  {o.note}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderAccommodation_PP() {
    const { pdf } = this.state;
    const { accommodation } = this.state.household;
    return (
      !isEmpty(accommodation) && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Accommodation</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                From
              </td>
              <td className="fieldTitle" colSpan={1}>
                To
              </td>
              <td className="fieldTitle" colSpan={1} />
            </tr>
            {accommodation.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td colSpan={1}>{o.youngPerson.contact.fullName}</td>
                <td colSpan={1}>{moment(o.dateStart).format('DD MMM YYYY')}</td>
                <td colSpan={1}>{moment(o.dateEnd).format('DD MMM YYYY')}</td>
                <td colSpan={1} />
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  render() {
    const { pdf, household } = this.state;
    const { user } = this.props;
    if (isEmpty(household) || isEmpty(user.roles)) return <GlobalLoading />;
    if (household.deleted)
      return (
        <div className="container">
          <div className="content">
            <div>
              <div
                className="list-search"
                style={{
                  margin: 'auto',
                  width: '50%',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                This household can not be found or has been deleted.
                <BackLink />
              </div>
            </div>
          </div>
        </div>
      );

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{household.name} - HEART1869</title>
          <meta
            name="description"
            content="Kennerley HEART1869 Household Detail"
          />
        </Helmet>
        <div className="content">
          <div className="content-body">
            <div className={!pdf ? 'printPreview' : 'printPDF'}>
              {this.renderPrintHeader_PP()}
              {this.state.printHousehold && this.renderMyDetails_PP()}
              {this.state.printOtherHouseholdMembers &&
                this.renderHouseholdMembers_PP()}
              {this.state.printCarers && this.renderCarers_PP()}
              {this.state.printCoordinator && this.renderCoordinator_PP()}
              {this.state.printPlacements && this.renderPlacements_PP()}
              {this.state.printAccommodation && this.renderAccommodation_PP()}
              {this.state.printNotes && this.renderNotes_PP()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HouseholdDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  household: state.household.detail,
  notes: state.household.detail.notes,
  user: state.user.profile,
});

export default connect(mapStateToProps)(HouseholdDetail);
