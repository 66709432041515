import keys from 'lodash/keys';
import find from 'lodash/find';

import * as HouseholdAPI from './HouseholdAPI';

import HouseholdModel from './HouseholdModel';
import NoteModel from '../Placement/NoteModel';
import { notification } from '../App/AppActions';

export const RENDER_HOUSEHOLDS = 'household/RENDER_HOUSEHOLDS';
export const LOADING_HOUSEHOLDS = 'household/LOADING_HOUSEHOLDS';
export const DISPLAY_HOUSEHOLD = 'household/DISPLAY_HOUSEHOLD';
export const UPDATE_HOUSEHOLD = 'household/UPDATE_HOUSEHOLD';
export const CHANGE_TAB = 'household/CHANGE_TAB';
export const CLEAR_SEARCH = 'household/CLEAR_SEARCH';
export const ADD_HOUSEHOLD_MEMBER = 'household/ADD_HOUSEHOLD_MEMBER';
export const EDIT_HOUSEHOLD_MEMBER = 'household/EDIT_HOUSEHOLD_MEMBER';
export const DELETE_HOUSEHOLD_MEMBER = 'household/DELETE_HOUSEHOLD_MEMBER';
export const ADD_HOUSEHOLD_SECONDARY = 'household/ADD_HOUSEHOLD_SECONDARY';
export const DELETE_HOUSEHOLD = 'household/DELETE_HOUSEHOLD';
export const ADD_NOTE = 'household/ADD_NOTE';
export const GET_NOTES = 'household/GET_NOTES';
export const GET_NOTE = 'household/GET_NOTE';
export const UPDATE_NOTE = 'household/UPDATE_NOTE';
export const EXIT_NOTE = 'household/EXIT_NOTE';
export const REMOVE_CARER = 'household/REMOVE_CARER';
export const HOUSEHOLDS_ERROR = 'household/HOUSEHOLDS_ERROR';

export function removeCarer(household, removePrimary) {
  return {
    type: REMOVE_CARER,
    household,
    removePrimary,
  };
}

export function exitNote() {
  return { type: EXIT_NOTE };
}

export function modifyNote(noteId, body) {
  return {
    type: UPDATE_NOTE,
    noteId,
    body,
  };
}

export function getNotes(notes) {
  return {
    type: GET_NOTES,
    notes,
  };
}

export function getNote(note) {
  return {
    type: GET_NOTE,
    note,
  };
}

export function addNote(note) {
  return {
    type: ADD_NOTE,
    note,
  };
}

export function changeTab(tab) {
  return {
    type: CHANGE_TAB,
    tab,
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export function renderHousehold(text, tab, tabChanged, households) {
  return {
    type: RENDER_HOUSEHOLDS,
    text,
    tab,
    tabChanged,
    households,
  };
}

export function getHousehold(household) {
  return {
    type: DISPLAY_HOUSEHOLD,
    household,
  };
}

export function updateHousehold(household, body, detail) {
  return {
    type: UPDATE_HOUSEHOLD,
    household,
    body,
    detail,
  };
}

export function updateHouseholdMember(
  household,
  member,
  body,
  contact,
  updatedMember
) {
  return {
    type: EDIT_HOUSEHOLD_MEMBER,
    household,
    member,
    body,
    contact,
    updatedMember,
  };
}

export function deleteHouseholdMember(
  household,
  member,
  body,
  contact,
  updatedMember
) {
  return {
    type: DELETE_HOUSEHOLD_MEMBER,
    household,
    member,
  };
}

export function loadMore(start) {
  return {
    type: LOADING_HOUSEHOLDS,
    start,
  };
}

export function _deleteHousehold(household) {
  return {
    type: DELETE_HOUSEHOLD,
    household,
  };
}

export function setError(error = false) {
  return {
    type: HOUSEHOLDS_ERROR,
    error,
  };
}

export function searchHousehold(text, start, count, type, tabChanged) {
  return dispatch => {
    dispatch(loadMore(start));
    return HouseholdAPI.searchHousehold(text, start, count, type).then(
      households => {
        dispatch(renderHousehold(text, type, tabChanged, households));
      },
      err => dispatch(setError(err || true))
    );
  };
}

export function fetchHousehold(_id, _print) {
  return dispatch =>
    HouseholdAPI.getHousehold(_id, _print).then(
      household => {
        if (household) {
          return HouseholdAPI.getNotes(_id, _print).then(notes =>
            HouseholdAPI.getAccommodation(_id).then(accommodation =>
              HouseholdAPI.getPlacements(_id).then(cases => {
                const householdWithPlacements = {
                  ...household,
                  accommodation,
                  cases,
                  notes,
                };
                return dispatch(getHousehold(householdWithPlacements));
              })
            )
          );
        }
        return dispatch(getHousehold({ deleted: true }));
      },
      () => Promise.reject()
    );
}

export function addHouseholdMember(member, checks) {
  let otherType = 'carersChildren';
  if (checks) otherType = 'otherIndividuals';
  return {
    type: ADD_HOUSEHOLD_MEMBER,
    member,
    otherType,
  };
}

export function addHouseholdSecondaryCarer(secondaryCarer) {
  return {
    type: ADD_HOUSEHOLD_SECONDARY,
    secondaryCarer,
  };
}

export function createMember(_id, member) {
  return dispatch =>
    HouseholdAPI.addHouseholdMember(_id, member).then(newMember => {
      dispatch(
        addHouseholdMember(
          newMember,
          newMember.checksRequiredFrom <= new Date()
        )
      );
    });
}

export function createSecondaryCarer(_id, carer) {
  return dispatch =>
    HouseholdAPI.addHouseholdSecondaryCarer(_id, carer).then(secondaryCarer => {
      dispatch(addHouseholdSecondaryCarer(secondaryCarer));
    });
}

export function modifyHousehold(
  _id,
  body,
  prevVal,
  undo = false,
  detail = true
) {
  return dispatch =>
    HouseholdAPI.updateHousehold(_id, body).then(() => {
      dispatch(updateHousehold(_id, body, detail));
      if (!undo) {
        dispatch(
          notification(
            `${find(HouseholdModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            modifyHousehold(_id, prevVal, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(HouseholdModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
}

export function editHouseholdMember(household, member, body) {
  return dispatch =>
    HouseholdAPI.updateHouseholdMember(household, member, body).then(m => {
      dispatch(updateHouseholdMember(household, member, body, body.contact, m));
      dispatch(fetchHousehold(household));
    });
}

export function removeHouseholdMember(household, member) {
  return dispatch =>
    HouseholdAPI.deleteHouseholdMember(household, member).then(() => {
      dispatch(deleteHouseholdMember(household, member));
      dispatch(fetchHousehold(household));
    });
}

export function changeHouseholdCoordinator(id, body, staff) {
  return dispatch =>
    HouseholdAPI.assignCoordinator(id, body).then(() => {
      dispatch(updateHousehold(id, { staff }, true));
    });
}

export function deleteHousehold(household) {
  return dispatch =>
    HouseholdAPI.deleteHousehold(household).then(() =>
      dispatch(_deleteHousehold(household))
    );
}

export function addHouseholdNote(_id, body) {
  return dispatch =>
    HouseholdAPI.createHouseholdNote(_id, body).then(note => {
      dispatch(addNote(note));
      dispatch(notification('Household note added.'));
      return note;
    });
}

export function fetchHouseholdNotes(_id) {
  return dispatch =>
    HouseholdAPI.getNotes(_id).then(notes => {
      dispatch(getNotes(notes));
      return notes;
    });
}

export function fetchHouseholdNote(householdId, noteId) {
  return dispatch =>
    HouseholdAPI.getNote(householdId, noteId).then(note => {
      dispatch(getNote(note));
    });
}

export function closeHouseholdNote() {
  return dispatch => {
    dispatch(exitNote());
  };
}

export function updateHouseholdNote(
  householdId,
  noteId,
  body,
  prevVal,
  undo = false
) {
  return dispatch =>
    HouseholdAPI.updateNote(householdId, noteId, body).then(() => {
      dispatch(modifyNote(noteId, body));
      dispatch(fetchHouseholdNotes(householdId));
      if (!undo) {
        dispatch(
          notification(
            `${find(NoteModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            updateHouseholdNote(householdId, noteId, prevVal, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(NoteModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
}

export function deleteHouseholdNote(householdId, noteId) {
  return dispatch =>
    HouseholdAPI.deleteNote(householdId, noteId).then(() => {
      dispatch(fetchHouseholdNotes(householdId));
    });
}

export function removeCarerFromHousehold(household, removePrimary) {
  return dispatch =>
    HouseholdAPI.removeCarerFromHousehold(household, removePrimary).then(() => {
      dispatch(removeCarer(household, removePrimary));
      return household;
    });
}
