import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';

import { fetchYoungPerson, getYPNotes } from '../YoungPersonActions';

import BackLink from '../../../components/BackLink';
import GlobalLoading from '../../../components/GlobalLoading';

import deepCompare from '../../../util/deepCompare';

import '../../../styles/PrintPreview.css';
import '../../../styles/PrintPDF.css';

import qs from 'query-string';

class YoungPersonDetail extends Component {
  constructor(props) {
    super(props);

    const query = qs.parse(props.location.search);

    this.state = {
      youngPerson: {},
      contact: {},
      referrals: [],
      viewsandwishes: [],
      notes: [],
      printContact: query.contact !== '0',
      printPersonal: query.personal !== '0',
      printEducation: query.education !== '0',
      printMedical: query.medical !== '0',
      printDoctors: query.doctors !== '0',
      printParents: query.parents !== '0',
      printEmergency: query.emergency !== '0',
      printSiblings: query.siblings !== '0',
      printCare: query.care !== '0',
      printPayment: query.payment !== '0',
      printReferrals: query.referrals !== '0',
      printPlacements: query.cases !== '0',
      printAccommodation: query.accommodation !== '0',
      printViewsandwishes: query.viewsandwishes !== '0',
      pdf: query.do === 'pdf',
    };

    props.dispatch(fetchYoungPerson(props.match.params.id));
    props.dispatch(getYPNotes(props.match.params.id));
  }
  shouldComponentUpdate(props, state) {
    const {
      user,
      youngPerson,
      contact,
      referrals,
      viewsandwishes,
      notes,
    } = this.props;
    return (
      !deepCompare(state, this.state) ||
      !deepCompare(props.user, user) ||
      !deepCompare(props.youngPerson, youngPerson) ||
      !deepCompare(props.contact, contact) ||
      !deepCompare(props.referrals, referrals) ||
      !deepCompare(props.viewsandwishes, viewsandwishes) ||
      !deepCompare(props.notes, notes)
    );
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setState({ youngPerson: {}, contact: {} });
      props.dispatch(fetchYoungPerson(props.match.params.id));
      props.dispatch(getYPNotes(props.match.params.id));
    } else
      this.setState({
        youngPerson: props.youngPerson,
        contact: props.contact,
        referrals: props.referrals,
        viewsandwishes: props.viewsandwishes,
        notes: props.notes,
      });
  }

  renderPrintHeader_PP(bla) {
    const { pdf, contact } = this.state;
    return (
      <table className={!pdf ? 'printPreviewHeader' : 'printPDFHeader'}>
        <tbody>
          <tr>
            <td colSpan={1}>YOUNGPERSON</td>
            <td colSpan={1}>{contact && contact.fullName}</td>
            <td colSpan={1}>HEART1869&nbsp;</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderContactDetails_PP(bla) {
    const { pdf, contact } = this.state;
    return (
      contact && (
        <table
          className={
            !pdf
              ? 'printPreviewTable ypContactDetails'
              : 'printPDFTable ypContactDetails'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Contact Details</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldValue" colSpan={3}>
                {contact.givenName + ' ' + contact.surname}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Also Knows As
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.aka}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Gender
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.gender}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Date of Birth
              </td>
              <td className="fieldValue" colSpan={1}>
                {moment(contact.birthDate).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Email
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.email}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Mobile Number
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.mobile}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Phone Number
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.phone}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Residential Address
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.residentialAddress.administrative_area_level_1 +
                  ', ' +
                  contact.residentialAddress.country}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Postal Address
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.postalAddress.administrative_area_level_1 +
                  ', ' +
                  contact.postalAddress.country}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderPersonalDetails_PP(bla) {
    const { pdf, youngPerson } = this.state;
    return (
      youngPerson && (
        <table
          className={
            !pdf
              ? 'printPreviewTable ypPersonalDetails'
              : 'printPDFTable ypPersonalDetails'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Personal Details</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Country of Birth
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.birthCountry}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Main Language Spoken at Home
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.homeLanguage}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                First Nations Connection
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.aboriginal}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Culturally and Linguistically Diverse Background
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.cald ? 'Yes' : 'No'}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Religion
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.religion}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Ethnicity
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.ethnicity}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Employer
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.employer}
              </td>
              <td className="fieldTitle" colSpan={1}>
                EmployerPhone
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.employerPhone}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderEducationDetails_PP(bla) {
    const { pdf, youngPerson } = this.state;
    return (
      youngPerson && (
        <table
          className={
            !pdf
              ? 'printPreviewTable ypEducationDetails'
              : 'printPDFTable ypEducationDetails'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Education Details</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                School
              </td>
              <td className="fieldValue" colSpan={3}>
                {youngPerson.school}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Undertaking Apprenticeship
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.apprenticeship ? 'Yes' : 'No'}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Undertaking Tertiary Education
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.tertiary}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Education Comments
              </td>
              <td className="fieldValue" colSpan={3}>
                {youngPerson.educationComments}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderMedicalDetails_PP(bla) {
    const { pdf, youngPerson } = this.state;
    return (
      youngPerson && (
        <table
          className={
            !pdf
              ? 'printPreviewTable ypMedicalDetails'
              : 'printPDFTable ypMedicalDetails'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Medical Details</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Health Concerns
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.healthConcerns}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Health Severity
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.severity}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Emergency Contact
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.econtactName}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Emergency Email
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.econtactEmail}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Emergency Phone
              </td>
              <td className="fieldValue" colSpan={3}>
                {youngPerson.econtactPhone}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderDoctors_pp(bla) {
    const { pdf } = this.state;
    const { doctors } = this.state.youngPerson;
    return (
      !isEmpty(doctors) && (
        <table
          className={
            !pdf ? 'printPreviewTable ypReferrals' : 'printPDFTable ypReferrals'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Doctors</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                Email
              </td>
              <td className="fieldTitle" colSpan={1}>
                Phone
              </td>
              <td className="fieldTitle" colSpan={1}>
                Practice Address
              </td>
            </tr>
            {doctors.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {o.contact.fullName}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.contact.email}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.contact.mobile ? o.contact.mobile : o.contact.phone}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.contact.residentialAddress.street_number
                    ? o.contact.residentialAddress.street_number
                    : ''}
                  {o.contact.residentialAddress.route
                    ? ', ' + o.contact.residentialAddress.route
                    : ''}
                  {o.contact.residentialAddress.postal_town
                    ? ', ' + o.contact.residentialAddress.postal_town
                    : ''}
                  {o.contact.residentialAddress.administrative_area_level_2
                    ? ', ' +
                      o.contact.residentialAddress.administrative_area_level_2
                    : ''}
                  {o.contact.residentialAddress.administrative_area_level_1
                    ? ', ' +
                      o.contact.residentialAddress.administrative_area_level_1
                    : ''}
                  {o.contact.residentialAddress.postal_code
                    ? ', ' + o.contact.residentialAddress.postal_code
                    : ''}
                  {o.contact.residentialAddress.country
                    ? ', ' + o.contact.residentialAddress.country
                    : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderCareDetails_PP(bla) {
    const { pdf, youngPerson } = this.state;
    return (
      youngPerson && (
        <table
          className={
            !pdf
              ? 'printPreviewTable ypCareDetails'
              : 'printPDFTable ypCareDetails'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Care Details</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Left Care Date
              </td>
              <td className="fieldValue" colSpan={1}>
                {moment(youngPerson.kennerleyEntryDate).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Legal Order Status
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.legalOrderStatus}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Wraparound Services
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.wrapAroundServices}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Child Safety Officer
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.csoName}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                CSO Email
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.cscEmail}
              </td>
              <td className="fieldTitle" colSpan={1}>
                CSO Phone
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.csoPhone}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Moving On Program
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.mop ? 'Yes' : 'No'}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Goals
              </td>
              <td className="fieldValue" colSpan={1}>
                {youngPerson.goals}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Additional Comments
              </td>
              <td className="fieldValue" colSpan={3}>
                {youngPerson.comments}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderPaymentClassifications_PP() {
    const { pdf } = this.state;
    const { payments } = this.state.youngPerson;
    return (
      !isEmpty(payments) && (
        <table
          className={
            !pdf ? 'printPreviewTable ypPayments' : 'printPDFTable ypPayments'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Payment Classifications</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Classification
              </td>
              <td className="fieldTitle" colSpan={1}>
                Start Date
              </td>
              <td className="fieldTitle" colSpan={1}>
                End Date
              </td>
              <td className="fieldTitle" colSpan={1} />
            </tr>
            {payments.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {o.classification}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.dateStart).format('DD MMM YYYY')}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.dateEnd).format('DD MMM YYYY')}
                </td>
                <td className="fieldValue" colSpan={1} />
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderParentGuardians_PP(bla) {
    const { pdf } = this.state;
    const { parents } = this.state.youngPerson;
    return (
      !isEmpty(parents) && (
        <table
          className={
            !pdf
              ? 'printPreviewTable ypParentGuardians'
              : 'printPDFTable ypParentGuardians'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Parents/Guardians</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                Email
              </td>
              <td className="fieldTitle" colSpan={1}>
                Phone
              </td>
              <td className="fieldTitle" colSpan={1}>
                Residential Address
              </td>
            </tr>
            {parents.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {o.contact.fullName}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.contact.email}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.contact.mobile ? o.contact.mobile : o.contact.phone}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.contact.residentialAddress.street_number
                    ? o.contact.residentialAddress.street_number
                    : ''}
                  {o.contact.residentialAddress.route
                    ? ', ' + o.contact.residentialAddress.route
                    : ''}
                  {o.contact.residentialAddress.postal_town
                    ? ', ' + o.contact.residentialAddress.postal_town
                    : ''}
                  {o.contact.residentialAddress.administrative_area_level_2
                    ? ', ' +
                      o.contact.residentialAddress.administrative_area_level_2
                    : ''}
                  {o.contact.residentialAddress.administrative_area_level_1
                    ? ', ' +
                      o.contact.residentialAddress.administrative_area_level_1
                    : ''}
                  {o.contact.residentialAddress.postal_code
                    ? ', ' + o.contact.residentialAddress.postal_code
                    : ''}
                  {o.contact.residentialAddress.country
                    ? ', ' + o.contact.residentialAddress.country
                    : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderEmergencyContacts_PP(bla) {
    const { pdf } = this.state;
    const { emergencyContacts } = this.state.youngPerson;
    return (
      !isEmpty(emergencyContacts) && (
        <table
          className={
            !pdf
              ? 'printPreviewTable ypEmergencyContacts'
              : 'printPDFTable ypEmergencyContacts'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Emergency Contacts</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                Email
              </td>
              <td className="fieldTitle" colSpan={1}>
                Phone
              </td>
              <td className="fieldTitle" colSpan={1}>
                Residential Address
              </td>
            </tr>
            {emergencyContacts.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {o.contact.fullName}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.contact.email}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.contact.mobile ? o.contact.mobile : o.contact.phone}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.contact.residentialAddress.street_number
                    ? o.contact.residentialAddress.street_number
                    : ''}
                  {o.contact.residentialAddress.route
                    ? ', ' + o.contact.residentialAddress.route
                    : ''}
                  {o.contact.residentialAddress.postal_town
                    ? ', ' + o.contact.residentialAddress.postal_town
                    : ''}
                  {o.contact.residentialAddress.administrative_area_level_2
                    ? ', ' +
                      o.contact.residentialAddress.administrative_area_level_2
                    : ''}
                  {o.contact.residentialAddress.administrative_area_level_1
                    ? ', ' +
                      o.contact.residentialAddress.administrative_area_level_1
                    : ''}
                  {o.contact.residentialAddress.postal_code
                    ? ', ' + o.contact.residentialAddress.postal_code
                    : ''}
                  {o.contact.residentialAddress.country
                    ? ', ' + o.contact.residentialAddress.country
                    : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderSiblings_PP(bla) {
    const { pdf } = this.state;
    const { siblings, siblingsInCare } = this.state.youngPerson;
    return (
      (!isEmpty(siblings) || !isEmpty(siblingsInCare)) && (
        <table
          className={
            !pdf ? 'printPreviewTable ypSiblings' : 'printPDFTable ypSiblings'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Siblings</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={1}>
                Email
              </td>
              <td className="fieldTitle" colSpan={1}>
                Phone
              </td>
              <td className="fieldTitle" colSpan={1}>
                Residential Address
              </td>
            </tr>
            {isEmpty(siblingsInCare)
              ? ''
              : siblingsInCare.map(o => (
                  <tr
                    className={
                      !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                    }
                  >
                    <td className="fieldValue" colSpan={1}>
                      {o.contact.fullName}
                    </td>
                    <td className="fieldValue" colSpan={1}>
                      {o.contact.email}
                    </td>
                    <td className="fieldValue" colSpan={1}>
                      {o.contact.mobile ? o.contact.mobile : o.contact.phone}
                    </td>
                    <td className="fieldValue" colSpan={1}>
                      {o.contact.residentialAddress.street_number
                        ? o.contact.residentialAddress.street_number
                        : ''}
                      {o.contact.residentialAddress.route
                        ? ', ' + o.contact.residentialAddress.route
                        : ''}
                      {o.contact.residentialAddress.postal_town
                        ? ', ' + o.contact.residentialAddress.postal_town
                        : ''}
                      {o.contact.residentialAddress.administrative_area_level_2
                        ? ', ' +
                          o.contact.residentialAddress
                            .administrative_area_level_2
                        : ''}
                      {o.contact.residentialAddress.administrative_area_level_1
                        ? ', ' +
                          o.contact.residentialAddress
                            .administrative_area_level_1
                        : ''}
                      {o.contact.residentialAddress.postal_code
                        ? ', ' + o.contact.residentialAddress.postal_code
                        : ''}
                      {o.contact.residentialAddress.country
                        ? ', ' + o.contact.residentialAddress.country
                        : ''}
                    </td>
                  </tr>
                ))}
            {isEmpty(siblings)
              ? ''
              : siblings.map(o => (
                  <tr
                    className={
                      !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                    }
                  >
                    <td className="fieldValue" colSpan={1}>
                      {o.contact.fullName}
                    </td>
                    <td className="fieldValue" colSpan={1}>
                      {o.contact.email}
                    </td>
                    <td className="fieldValue" colSpan={1}>
                      {o.contact.mobile ? o.contact.mobile : o.contact.phone}
                    </td>
                    <td className="fieldValue" colSpan={1}>
                      {o.contact.residentialAddress.street_number
                        ? o.contact.residentialAddress.street_number
                        : ''}
                      {o.contact.residentialAddress.route
                        ? ', ' + o.contact.residentialAddress.route
                        : ''}
                      {o.contact.residentialAddress.postal_town
                        ? ', ' + o.contact.residentialAddress.postal_town
                        : ''}
                      {o.contact.residentialAddress.administrative_area_level_2
                        ? ', ' +
                          o.contact.residentialAddress
                            .administrative_area_level_2
                        : ''}
                      {o.contact.residentialAddress.administrative_area_level_1
                        ? ', ' +
                          o.contact.residentialAddress
                            .administrative_area_level_1
                        : ''}
                      {o.contact.residentialAddress.postal_code
                        ? ', ' + o.contact.residentialAddress.postal_code
                        : ''}
                      {o.contact.residentialAddress.country
                        ? ', ' + o.contact.residentialAddress.country
                        : ''}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      )
    );
  }

  renderReferrals_PP(bla) {
    const { pdf } = this.state;
    const { referrals } = this.state.youngPerson;
    return (
      !isEmpty(referrals) &&
      referrals.map((o, i) => (
        <span>
          <table
            className={
              !pdf
                ? 'printPreviewTable ypReferrals'
                : 'printPDFTable ypReferrals'
            }
          >
            <tbody>
              <tr
                className={
                  !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
                }
              >
                <td colSpan={4}>
                  Referral {i + 1} of {referrals.length}
                </td>
              </tr>
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldTitle" colSpan={1}>
                  Source
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.source}
                </td>
                <td className="fieldTitle" colSpan={1}>
                  Placement Type
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.placementType}
                </td>
              </tr>
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldTitle" colSpan={1}>
                  Service Requested
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.servicesProvided}
                </td>
                <td className="fieldTitle" colSpan={1}>
                  Legal Order Status
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.legalOrderStatus}
                </td>
              </tr>
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldTitle" colSpan={1}>
                  Proposed Start Date
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.proposedStart).format('DD MMM YYYY')}
                </td>
                <td className="fieldTitle" colSpan={1}>
                  Proposed End Date
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.proposedEnd).format('DD MMM YYYY')}
                </td>
              </tr>
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldTitle" colSpan={1}>
                  Closed
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.closed ? 'Yes' : 'No'}
                </td>
                <td className="fieldTitle" colSpan={1}>
                  Closed Date
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.closedDate).format('DD MMM YYYY')}
                </td>
              </tr>
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldTitle" colSpan={1}>
                  Reason for Closure
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.closedReason}
                </td>
                <td className="fieldTitle" colSpan={1}>
                  Reasons for Not Placing
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.notPlacingReasons}
                </td>
              </tr>
              {o.comments && (
                <tr
                  className={
                    !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                  }
                >
                  <td className="fieldTitle" colSpan={1}>
                    Additional Comments
                  </td>
                  <td className="fieldValue" colSpan={3}>
                    {o.comments}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {this.state.printPlacements && this.renderPlacements_PP(o, i + 1)}
        </span>
      ))
    );
  }

  renderPlacementNotes_PP(bla, idx, referral, ref_idx) {
    const { pdf } = this.state;
    const { notes } = this.state.youngPerson;
    let nts = [];

    if (!isEmpty(notes)) {
      nts = notes.filter(o => o.case === bla._id);
    }

    return (
      !isEmpty(nts) &&
      nts.map((o, i) => (
        <table
          className={
            !pdf ? 'printPreviewTable ypNotes' : 'printPDFTable ypNotes'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              {referral ? (
                <td colSpan={4}>
                  Referral {ref_idx} - Note {i + 1} of {nts.length}
                </td>
              ) : (
                <td colSpan={4}>
                  Placement {idx} - Note {i + 1} of {nts.length}
                </td>
              )}
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Date Recorded
              </td>
              <td className="fieldValue" colSpan={1}>
                {moment(o.dateRecorded).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Title
              </td>
              <td className="fieldValue" colSpan={1}>
                {o.title}
              </td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Category
              </td>
              <td className="fieldValue" colSpan={1}>
                {o.category}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Communication
              </td>
              <td className="fieldValue" colSpan={1}>
                {o.communication}
              </td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Incident Reporting
              </td>
              <td className="fieldValue" colSpan={1}>
                {o.incidentReporting}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Concern in Care
              </td>
              <td className="fieldValue" colSpan={1}>
                {o.concernInCare ? 'Yes' : 'No'}
              </td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Quality of Care
              </td>
              <td className="fieldValue" colSpan={1}>
                {o.qualityOfCare ? 'Yes' : 'No'}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Sensitive Record
              </td>
              <td className="fieldValue" colSpan={1}>
                {o.sensitive ? 'Yes' : 'No'}
              </td>
            </tr>
            {o.note && (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldTitle" colSpan={1}>
                  Note
                </td>
                <td className="fieldValue" colSpan={3}>
                  {o.note}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ))
    );
  }

  renderPlacements_PP(referral, idx) {
    const { pdf } = this.state;
    const cases = referral
      ? [{ ...referral.case }]
      : this.state.youngPerson.cases;
    return (
      !isEmpty(cases) &&
      cases.map((o, i) => (
        <span>
          <table
            className={
              !pdf
                ? 'printPreviewTable ypPlacements'
                : 'printPDFTable ypPlacements'
            }
          >
            <tbody>
              <tr
                className={
                  !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
                }
              >
                {referral ? (
                  <td colSpan={4}>Referral {idx} - Placement</td>
                ) : (
                  <td colSpan={4}>
                    Placement {i + 1} of {cases.length}
                  </td>
                )}
              </tr>
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldTitle" colSpan={1}>
                  Care Type
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.careType}
                </td>
                <td className="fieldTitle" colSpan={1}>
                  Services Requested
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.servicesProvided}
                </td>
              </tr>
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldTitle" colSpan={1}>
                  Date Entered Care with Kennerley
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.kennerleyEntryDate).format('DD MMM YYYY')}
                </td>
                <td className="fieldTitle" colSpan={1}>
                  Date Left Care
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.leftCareDate).format('DD MMM YYYY')}
                </td>
              </tr>
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldTitle" colSpan={1}>
                  Placement Breakdown
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.placementBreakdown ? 'Yes' : 'No'}
                </td>
                <td className="fieldTitle" colSpan={1}>
                  Reason Left Care
                </td>
                <td className="fieldValue" colSpan={1}>
                  {o.leftCareReason}
                </td>
              </tr>
              {o.information && (
                <tr
                  className={
                    !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                  }
                >
                  <td className="fieldTitle" colSpan={1}>
                    General Information
                  </td>
                  <td className="fieldValue" colSpan={3}>
                    {o.information}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {this.renderPlacementNotes_PP(o, i + 1, referral, idx)}
        </span>
      ))
    );
  }

  renderAccommodation_PP(bla) {
    const { pdf } = this.state;
    const { accommodation } = this.state.youngPerson;
    return (
      !isEmpty(accommodation) && (
        <table
          className={
            !pdf
              ? 'printPreviewTable ypAccommodation'
              : 'printPDFTable ypAccommodation'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Accommodation</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Household
              </td>
              <td className="fieldTitle" colSpan={1}>
                Start Date
              </td>
              <td className="fieldTitle" colSpan={1}>
                End Date
              </td>
              <td className="fieldTitle" colSpan={1} />
            </tr>
            {accommodation.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {o.alternate || o.household.name}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.dateStart).format('DD MMM YYYY')}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.dateEnd).format('DD MMM YYYY')}
                </td>
                <td className="fieldValue" colSpan={1} />
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderViewsAndWishes_PP(bla) {
    const { pdf } = this.state;
    const { viewsandwishes } = this.state.youngPerson;
    return (
      !isEmpty(viewsandwishes) && (
        <table
          className={
            !pdf
              ? 'printPreviewTable ypViewsAndWishes'
              : 'printPDFTable ypViewsAndWishes'
          }
        >
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Views & Wishes</td>
            </tr>
            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Title
              </td>
              <td className="fieldTitle" colSpan={1}>
                Date Recorded
              </td>
              <td className="fieldTitle" colSpan={2}>
                Detail
              </td>
            </tr>
            {viewsandwishes.map(o => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {o.title}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {moment(o.dateRecorded).format('DD MMM YYYY')}
                </td>
                <td className="fieldValue" colSpan={2}>
                  {o.detail}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  render() {
    const { pdf, youngPerson, contact } = this.state;
    const { user } = this.props;
    if (isEmpty(youngPerson) || isEmpty(user.roles)) return <GlobalLoading />;
    if (youngPerson.deleted)
      return (
        <div className="container">
          <div className="content">
            <div>
              <div
                className="list-search"
                style={{
                  margin: 'auto',
                  width: '50%',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                This young person can not be found or has been deleted.
                <BackLink />
              </div>
            </div>
          </div>
        </div>
      );

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {contact ? contact.fullName : 'Young Person'} - HEART1869
          </title>
          <meta
            name="description"
            content="Kennerley HEART1869 Young Person Detail"
          />
        </Helmet>
        <div className="content">
          <div className="content-body">
            <div className={!pdf ? 'printPreview' : 'printPDF'}>
              {this.renderPrintHeader_PP()}
              {this.state.printContact && this.renderContactDetails_PP()}
              {this.state.printPersonal && this.renderPersonalDetails_PP()}
              {this.state.printEducation && this.renderEducationDetails_PP()}
              {this.state.printMedical && this.renderMedicalDetails_PP()}
              {this.state.printMedical && this.renderDoctors_pp()}
              {this.state.printCare && this.renderCareDetails_PP()}
              {this.state.printPayment &&
                this.renderPaymentClassifications_PP()}
              {this.state.printParents && this.renderParentGuardians_PP()}
              {this.state.printEmergency && this.renderEmergencyContacts_PP()}
              {this.state.printSiblings && this.renderSiblings_PP()}
              {this.state.printReferrals && this.renderReferrals_PP()}
              {!this.state.printReferrals &&
                this.state.printPlacements &&
                this.renderPlacements_PP()}
              {this.state.printAccommodation && this.renderAccommodation_PP()}
              {this.state.printViewsandwishes && this.renderViewsAndWishes_PP()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

YoungPersonDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  youngPerson: state.young.detail,
  contact: state.young.detail.contact,
  referrals: state.young.detail.referrals,
  viewsandwishes: state.young.detail.viewsandwishes,
  notes: state.young.detail.notes,
  user: state.user.profile,
});

export default connect(mapStateToProps)(YoungPersonDetail);
