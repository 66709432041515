import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { initializeIcons } from '@uifabric/icons';

import isEmpty from 'lodash/isEmpty';

import MDrawer from 'material-ui/Drawer';
import ListItem from 'material-ui/MenuItem';
import Avatar from 'material-ui/Avatar';
import Divider from 'material-ui/Divider';

import { logout } from '../../User/UserActions';
import { refreshReports } from '../AppActions';
import NavLink from '../../../components/NavLink';

import './Drawer.css';
import config from '../../../config';
import { colours, icons } from '../../../theme';
import kibana from '../../../kibana.svg';
import CircularProgress from 'material-ui/CircularProgress';

function getIcon(pathname) {
  const style = { color: 'white' };
  const i = {
    home: <icons.DashboardIcon {...style} />,
    carer: <icons.CarerIcon {...style} />,
    young: <icons.YoungPersonIcon {...style} />,
    case: <icons.PlacementIcon {...style} />,
    household: <icons.HomeIcon {...style} />,
    staff: <icons.StaffIcon {...style} />,
    prospect: <icons.ProspectIcon {...style} />,
    referral: <icons.ReferralIcon {...style} />,
    settings: <icons.SettingsIcon {...style} />,
    logs: <icons.LogIcon {...style} />,
    me: <icons.MeIcon {...style} />,
    reports: <icons.AssessmentIcon {...style} />,
    kibana: <img src={kibana} alt="Kibana Logo" />,
    accessdb: <icons.ArchiveIcon {...style} />,
    keywords: <icons.FormIcon {...style} />,
    recent: <icons.HistoryIcon {...style} />,
  };
  const splitPathname = pathname.split(/\//);
  const section = splitPathname[1];
  // const id = splitPathname[2]; // TODO use with profile images later...
  return i[section] || <icons.DashboardIcon />;
}
class Drawer extends Component {
  constructor() {
    super();
    initializeIcons(undefined, { disableWarnings: true });
  }
  renderLinks() {
    const { isMobile, isTablet } = this.context;
    const {
      user,
      onRequestChange,
      dispatch,
      recent,
      reportProgress,
    } = this.props;
    const { roles } = user;
    let isAdmin = false;
    if (roles) {
      isAdmin =
        user.roles.includes('admin') || user.roles.includes('moderator');
    }
    const common = {
      activeClassName: 'nav-active',
      className: 'nav',
      onClick: isMobile() && !isTablet() ? onRequestChange : () => ({}),
    };
    return (
      <div>
        {user ? (
          <ListItem
            className="drawer-list-item"
            primaryText="Recent"
            leftIcon={getIcon('/recent')}
            primaryTogglesNestedList
            disabled={recent.length === 0}
            nestedItems={recent.map(r => (
              <NavLink {...common} key={r.id} to={`/${r.type}/${r.id}`}>
                <ListItem className="drawer-list-item" primaryText={r.title} />
              </NavLink>
            ))}
          />
        ) : null}
        <Divider style={{ backgroundColor: colours.steelBlue }} />
        <NavLink
          {...common}
          to="/home"
          isActive={(match, location) => location.pathname === '/' || match}
        >
          <ListItem
            className="drawer-list-item"
            primaryText="Dashboard"
            leftIcon={getIcon('/home')}
          />
        </NavLink>
        <NavLink {...common} to="/me">
          <ListItem
            className="drawer-list-item"
            primaryText="My Profile"
            leftIcon={getIcon('/me')}
          />
        </NavLink>
        <NavLink {...common} to="/prospect">
          <ListItem
            className="drawer-list-item"
            primaryText="Prospects"
            leftIcon={getIcon('/prospect')}
          />
        </NavLink>
        <NavLink {...common} to="/carer">
          <ListItem
            className="drawer-list-item"
            primaryText="Carers"
            leftIcon={getIcon('/carer')}
          />
        </NavLink>

        <NavLink {...common} to="/household">
          <ListItem
            className="drawer-list-item"
            primaryText="Households"
            leftIcon={getIcon('/household')}
          />
        </NavLink>
        <NavLink {...common} to="/young">
          <ListItem
            className="drawer-list-item"
            primaryText="Young People"
            leftIcon={getIcon('/young')}
          />
        </NavLink>
        <NavLink {...common} to="/referral">
          <ListItem
            className="drawer-list-item"
            primaryText="Referrals"
            leftIcon={getIcon('/referral')}
          />
        </NavLink>
        <NavLink {...common} to="/case">
          <ListItem
            className="drawer-list-item"
            primaryText="Placements"
            leftIcon={getIcon('/case')}
          />
        </NavLink>
        <Divider style={{ backgroundColor: colours.steelBlue }} />
        {isAdmin ? (
          <NavLink {...common} to="/staff">
            <ListItem
              className="drawer-list-item"
              primaryText="Staff"
              leftIcon={getIcon('/staff')}
            />
          </NavLink>
        ) : null}
        {isAdmin ? (
          <a
            className="nav"
            href={`${config.reports}/app/kibana#/dashboards`}
            target="kibana"
            rel="noopener noreferrer"
          >
            <ListItem
              className="drawer-list-item"
              primaryText="View Reports"
              leftIcon={getIcon('/kibana')}
            />
          </a>
        ) : null}
        {isAdmin ? (
          <ListItem
            className="drawer-list-item"
            primaryText="Update Reports"
            leftIcon={
              reportProgress > 0 ? (
                <CircularProgress
                  mode="determinate"
                  value={reportProgress}
                  size={20}
                  thickness={2}
                  color="white"
                  className="progress"
                />
              ) : (
                getIcon('/reports')
              )
            }
            onClick={() => {
              this.setState({ reportsUpdating: true });
              this.props
                .dispatch(refreshReports())
                .then(hello => this.setState({ reportsUpdating: false }));
            }}
          />
        ) : null}
        {isAdmin ? (
          <NavLink {...common} to="/keywords">
            <ListItem
              className="drawer-list-item"
              primaryText="Keywords Config"
              leftIcon={getIcon('/keywords')}
            />
          </NavLink>
        ) : null}
        {isAdmin ? (
          <NavLink {...common} to="/logs">
            <ListItem
              className="drawer-list-item"
              primaryText="Logs"
              leftIcon={getIcon('/logs')}
            />
          </NavLink>
        ) : null}
        <Divider style={{ backgroundColor: colours.steelBlue }} />
        <NavLink {...common} to="/accessdb">
          <ListItem
            className="drawer-list-item"
            primaryText="AccessDb"
            leftIcon={getIcon('/accessdb')}
          />
        </NavLink>
        <Divider style={{ backgroundColor: colours.steelBlue }} />
        <ListItem
          key="Logout"
          primaryText="Return to Office"
          onClick={() => {
            dispatch(logout());
            window.location.href = 'https://www.office.com/?auth=2&home=1';
          }}
          className="drawer-list-item"
          leftIcon={<FontIcon iconName="OfficeLogo" className="office-logo" />}
        />
      </div>
    );
  }
  render() {
    const { isMobile, isTablet } = this.context;
    const { onRequestChange, user, open } = this.props;
    return (
      <MDrawer
        open={open}
        className="drawer"
        docked={isMobile() && !isTablet()}
        zDepth={1}
        onRequestChange={onRequestChange}
        containerClassName="drawer-content"
      >
        <div className="pic-container">
          <Avatar
            className="profile-pic"
            icon={getIcon(window.location.pathname)}
            size={120}
            backgroundColor={colours.steelBlue}
            color="white"
          />
        </div>
        <Divider style={{ backgroundColor: colours.steelBlue }} />
        {!isEmpty(user) && this.renderLinks()}
      </MDrawer>
    );
  }
}

Drawer.contextTypes = {
  isMobile: PropTypes.func.isRequired,
  isTablet: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.profile,
  recent: state.app.recent,
  reportProgress: state.app.reportProgress,
});

export default connect(mapStateToProps)(Drawer);
